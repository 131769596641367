// ==========================================================================================
// Mixins
// ==========================================================================================

@mixin for-monitors-up {
	@media (min-width: 1520px) {
		@content;
	}
}

@mixin for-desktop-up {
	@media (min-width: 1280px) {
		@content;
	}
}

@mixin for-laptop-up {
	@media (min-width: 992px) {
		@content;
	}
}

@mixin for-tablet-down {
	@media (max-width: 991px) {
		@content;
	}
}

@mixin for-mobile-down {
	@media (max-width: 639px) {
		@content;
	}
}



// ==========================================================================================
// Font Imports
// ==========================================================================================

@font-face {
	font-family: "Bebas Neue";
	src: url("../fonts/BebasNeue/BebasNeue-Regular.eot");
	src: url("../fonts/BebasNeue/BebasNeue-Regular.eot?#iefix") format("embedded-opentype"),
		url("../fonts/BebasNeue/BebasNeue-Regular.woff2") format("woff2"),
		url("../fonts/BebasNeue/BebasNeue-Regular.woff") format("woff"),
		url("../fonts/BebasNeue/BebasNeue-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Wix";
	src: url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.eot");
	src: url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.eot?#iefix") format("embedded-opentype"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.woff2") format("woff2"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.woff") format("woff"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: "Wix";
	src: url("../fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.eot");
	src: url("../fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.woff2") format("woff2"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.woff") format("woff"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Wix";
	src: url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.eot");
	src: url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.woff2") format("woff2"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.woff") format("woff"),
		url("../fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}



// ==========================================================================================
// Color Definitions
// ==========================================================================================

$black: #000000;
$space: #292C2E;
$space-cadet: #323638;
$cadet: #4E5357;
$slate: #878D91;
$gray: #CCCFD0;
$steel: #E6E7E8;
$snow: #F0F1F2;
$snow-white: #FAFAFA;
$platinum: #FAFAFA;
$white: #FFFFFF;

$orange: #FF5533;
$burst: #ff4838;
$burst-light: #FFCFCA;
$meadow: #44AE75;
$meadow-light: #C4E8D5;
$ocean: #0084FF;



// ==========================================================================================
// Color Theme
// ==========================================================================================

// Frames
$main-background: $platinum;
$modal-background: $platinum;
$modal-muted-background: $snow-white;
$modal-border: $steel;

$component-placeholder: $steel;

// Text
$body-text: $cadet;
$heading-text: $space;
$link-text: $ocean;
$muted-text: $slate;
$muted-hover-text: $cadet;
$faint-text: $gray;
$alt-text: $white;

// Buttons
$button-primary-background: $orange;
$button-primary-text: $white;
$button-primary-hover-background: $space;
$button-primary-hover-text: $white;

$button-secondary-background: $snow;
$button-secondary-text: $cadet;
$button-secondary-hover-background: $steel;
$button-secondary-hover-text: $space;

$button-tertiary-background: transparent;
$button-tertiary-text: $cadet;
$button-tertiary-border: $steel;
$button-tertiary-hover-background: $steel;
$button-tertiary-hover-text: $space;

$button-success-background: $meadow-light;
$button-success-text: $meadow;

$button-dark-background: $cadet;
$button-dark-text: $snow;
$button-dark-hover-background: $space;
$button-dark-hover-text: $white;

$button-fab-background: $snow;
$button-fab-text: $cadet;
$button-fab-hover-background: $white;
$button-fab-hover-text: $cadet;

// Nav
$nav-background: $white;
$nav-text: $cadet;
$nav-hover-background: $snow;
$nav-hover-text: $cadet;
$nav-active-background: $snow;
$nav-active-text: $space;

// Forms
$field-background: $snow-white;
$field-text: $cadet;
$field-placeholder: $slate;
$field-border: $steel;
$field-hover-background: $snow;

// Errors
$error-background: $burst-light;
$error-text: $burst;

// Other
$indicator-background: $orange;



// ==========================================================================================
// Global styles
// ==========================================================================================

* {
	position: relative;
	z-index: 1;
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	font-size: 16px;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	font-family: "Wix", Helvetica, sans-serif;
	line-height: 1.4;
	scrollbar-width: none;

	@include for-tablet-down {
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

html,
body {
	overscroll-behavior: none;
}

body {
	width: 100vw;
	max-height: 100vh;
	font-weight: 500;
	font-style: normal;
	font-family: "Wix", Helvetica, sans-serif;
	background-color: $platinum;
}

#root {
	position: relative;
	z-index: 1200;
	display: flex;
	width: 100vw;
	height: 100%;
	overflow-x: hidden;

	@include for-tablet-down {
		flex-direction: column;
	}
}



// ==========================================================================================
// Links & buttons
// ==========================================================================================

.button__indicator {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	display: flex;
	width: 10px;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	background-color: $orange;
	background: $indicator-background;
}



// ==========================================================================================
// Forms
// ==========================================================================================

.form__textfield {
	width: 100%;
	height: 50px;
	padding: 0px 16px;
	background: transparent;
	font-family: inherit;
	font-size: 16px;
	border-style: solid;
	border-width: 1px;
	outline: 0;
	transition: border-color 0.2s;
	border-radius: 6px;
	background-color: $field-background;
	color: $field-text;
	border-color: $field-border;

	&::placeholder {
		color: $field-placeholder;
		font-size: 16px;
	}
}



// ==========================================================================================
// Swiper
// ==========================================================================================

.swiper {
	@include for-laptop-up {
		&:hover {
			.swiper-button {
				opacity: 0.9;
			}
		}
	}
}

.swiper-button {
	position: absolute;
	top: calc(50% - (28px / 2));
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	box-shadow: 0 8px 24px 0 rgba(black, 0.3);
	opacity: 0;
	cursor: pointer;
	transition: all 0.15s ease-out;
	color: $button-fab-text;
	background-color: $button-fab-background;

	@include for-laptop-up {
		&:hover {
			color: $button-fab-hover-text;
			background-color: $button-fab-hover-background;
			transform: scale(1.05);
		}
	}

	svg {
		font-size: 14px;
	}

	&.prev {
		left: 12px;
	}

	&.next {
		right: 12px;
	}

	&.swiper-button-disabled {
		opacity: 0 !important;
	}
}

.swiper-pagination-bullet {
	background-color: $white;
	opacity: 1;
}

// Below are styles specific to media page swiper

.swiper-fade {
	aspect-ratio: 500 / 333;
	background-color: $component-placeholder;
}

.swiper-fade,
.swiper-thumbs {
	position: relative;
	width: 100%;

	img {
		width: 100%;
		-webkit-user-select: none;
		/* Safari */
		-ms-user-select: none;
		/* IE 10 and IE 11 */
		user-select: none;
		/* Standard syntax */
	}
}

.swiper-thumbs {
	padding: 8px 12px;
	min-height: 0;

	@include for-mobile-down() {
		padding: 8px;
	}

	.swiper-slide {
		aspect-ratio: 1 / 1 !important;
		min-height: 0;
		opacity: 0.7;
		cursor: pointer;

		&.swiper-slide-thumb-active {
			opacity: 1;
		}
	}

	img {
		aspect-ratio: 1 / 1 !important;
		border-radius: 6px;
		object-fit: cover;
		-webkit-user-select: none;
		/* Safari */
		-ms-user-select: none;
		/* IE 10 and IE 11 */
		user-select: none;
		/* Standard syntax */
	}
}



// ==========================================================================================
// Misc Elements
// ==========================================================================================

.nav-icon {
	width: 18;
	height: 18;

	@include for-tablet-down {
		width: 21;
		height: 21;
	}

	&.small {
		margin: 0 1px;
		width: 16;
		height: 16;

		@include for-tablet-down {
			margin: 1.25px 0;
			width: 18.5;
			height: 18.5;
		}
	}
}

.MuiTab-root {
	font-weight: 600 !important;

	&.Mui-selected {
		color: $space !important;
	}

	&.Mui-disabled {
		color: $gray !important;
	}
}

.MuiAccordionDetails-root {
	margin: 0 !important;
	padding: 0 !important;
}

.group {
	scroll-margin-top: 72px;
}

.gm-style {
	z-index: 1000 !important;
}

.notistack-SnackbarContainer.z-alert {
	z-index: 10000;
}



// ==========================================================================================
// Utility Classes
// ==========================================================================================

.md-down-hide {
	@include for-tablet-down {
		display: none !important;
	}
}



// ==========================================================================================
// Animations
// ==========================================================================================

@keyframes loader {
	0% {
		opacity: 1.0;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1.0;
	}
}

@keyframes pulse-ring {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	30% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(2);
		opacity: 0;
	}
}